import React from 'react';

import PropTypes from 'prop-types';

import ArrowUp from '../../images/survey_dashboard/arrow_up.svg';
import ArrowDown from '../../images/survey_dashboard/arrow_down.svg';

ToggleHeader.propTypes = {
  title: PropTypes.string.isRequired,
  toggleValue: PropTypes.bool.isRequired,
  toggleFunction: PropTypes.func.isRequired,
  summary: PropTypes.string,
  additionalClasses: PropTypes.string,
};

/**
 * A header for toggling modal panel visibility
 * TODO: hand cursor for toggle icon
 * @param { Object } props
 * @return { JSX.Element } the header panel
*/
function ToggleHeader(props) {
  const summary = () => {
    if (props.toggleValue) {
      return null;
    }

    return (
      <span>
        { props.summary}
      </span>
    );
  };

  const headerClass = () => {
    const expansionClass = props.toggleValue ? 'expanded' : 'collapsed';
    const defaultClass = `collapsible-panel-toggle-header`;

    return `${defaultClass} ${expansionClass} ${props.additionalClasses}`;
  };

  return (
    <div
      className={headerClass()}
      onClick={() => props.toggleFunction(!props.toggleValue)}
    >
      <h2 className='collapsible-panel-title'>
        {props.title}
        {summary()}
      </h2>
      <img
        className='collapsible-panel-toggle'
        src={ props.toggleValue ? ArrowUp : ArrowDown }
      />
    </div>
  );
}

export default ToggleHeader;
