import React from 'react';
import PropTypes from 'prop-types';

import Modal from 'react-bootstrap/Modal';

import Select from 'react-select';

import ModalPanel from '../survey_editor/ModalPanel';

GeneralOptions.propTypes = {
  auditLog: PropTypes.array.isRequired,
  setTagSelections: PropTypes.func.isRequired,
  panelExpansionSettings: PropTypes.object.isRequired,
  updatePanelExpansionSettings: PropTypes.func.isRequired,
  appliedSurveyTags: PropTypes.array.isRequired,
  unappliedSurveyTags: PropTypes.array.isRequired,
};

/**
 * Render survey general tab
 * @param {object} props - see propTypes
 * @return {JSX.Element}
*/
function GeneralOptions(props) {
  const [showModal, setShowModal] = React.useState(false);

  // TODO handle < 2
  const auditSample = [props.auditLog[0], props.auditLog[1]];

  AuditModal.propTypes = {
    children: PropTypes.node.isRequired,
  };
  /**
   * Render survey audit log modal
   * @param {object} props - see propTypes
   * @return {JSX.Element}
  */
  function AuditModal(props) {
    return (
      <Modal
        style={{opacity: 1}}
        show={showModal}
        onHide={() => {
          setShowModal(false);
        }}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title
            className='settings-modal-title'
          >
            Last 10 Audits
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className='audit-modal'>
          {props.children}
        </Modal.Body>
      </Modal>
    );
  }

  AuditLine.propTypes = {
    audit: PropTypes.object.isRequired,
  };
  /**
   * Render a single audited change
   * TODO: Handle the many audit edge cases
   *
   * @param {object} audit
   * @return {JSX.Element}
  */
  function AuditLine({audit}) {
    return (
      <li>
        {audit.createdAt} - { audit.username } - { audit.actionDescription }

        {
          audit.values ?
            <ul>
              {
                Object.keys(audit.values[0]).map((key) => {
                  let change = '';

                  if (Array.isArray(audit.values[0][key])) {
                    const oldValue = audit.values[0][key][0];
                    const newValue = audit.values[0][key][1];

                    change = `${oldValue} - ${newValue}`;
                  } else {
                    change = `${audit.values[0][key]}`;
                  }

                  return <li key={key}>{key}: {change}</li>;
                })
              }
            </ul> :
              null
        }
      </li>
    );
  }

  AuditLogPanel.propTypes = {
    auditLog: PropTypes.array.isRequired,
    panelExpansionSettings: PropTypes.object.isRequired,
    updatePanelExpansionSettings: PropTypes.func.isRequired,
  };
  /**
   * Render the audit log panel
   * @param {object} auditLog
   * @return {JSX.Element}
  */
  function AuditLogPanel({auditLog}) {
    return (
      <ModalPanel
        panelTitle='Audit Log'
        panelExpansionSettings={props.panelExpansionSettings}
        updatePanelExpansionSettings={props.updatePanelExpansionSettings}
        expandByDefault
      >
        <ul>
          {
            auditSample.map((audit) => {
              return <AuditLine audit={audit} key={audit.createdAt}/>;
            })
          }
        </ul>
        {
          auditLog.length > 2 ?
            <>
              <input
                type='button'
                className='sidebar-button audit-log-trigger'
                onClick={() => {
                  setShowModal(true);
                }}
                value='MORE'
              />
              <AuditModal auditLog={auditLog}>
                <ul>
                  {
                    auditLog.map((audit) => {
                      return <AuditLine audit={audit} key={audit.createdAt}/>;
                    })
                  }
                </ul>
                <a
                  className='sidebar-button audit-log-button'
                  href='/my_account/audit/'
                  target='_blank'
                >
                  See All
                </a>
              </AuditModal>
            </> :
              null
        }
      </ModalPanel>
    );
  }

  const onTagSelectionChange = (selectedOptions) => {
    props.setTagSelections(selectedOptions);
  };

  return (
    <>
      <ModalPanel
        panelTitle='Tags'
        panelExpansionSettings={props.panelExpansionSettings}
        updatePanelExpansionSettings={props.updatePanelExpansionSettings}
        expandByDefault
      >
        <Select
          defaultValue={props.appliedSurveyTags}
          isMulti
          options={props.unappliedSurveyTags}
          className="basic-multi-select tag-selector"
          classNamePrefix="select"
          onChange={onTagSelectionChange}
        />

        <a
          className='sidebar-button'
          href='/survey_tags'
          target='_blank'
        >
          MANAGE TAGS
        </a>

      </ModalPanel>
      <AuditLogPanel
        auditLog={props.auditLog}
        panelExpansionSettings={props.panelExpansionSettings}
        updatePanelExpansionSettings={props.updatePanelExpansionSettings}
      />
    </>
  );
}

export default GeneralOptions;
