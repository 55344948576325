import React from 'react';
import PropTypes from 'prop-types';

import Modal from 'react-bootstrap/Modal';

SettingsModal.propTypes = {
  engine: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  modalTrigger: PropTypes.node,
};

/**
 * A modal dialog for node settings
 * @param { Object } props
 * @return { JSX.Element } the settings modal
*/
function SettingsModal(props) {
  const [show, setShow] = React.useState(false);

  const handleClose = () => {
    setShow(false);
    props.engine.repaintCanvas();
  };

  const handleShow = () => setShow(true);

  return (
    <>
      <a href='#' onClick={handleShow}>
        {props.modalTrigger}
      </a>

      <Modal
        style={{opacity: 1}}
        show={show}
        onHide={handleClose}
        centered
        enforceFocus={false}
      >
        <Modal.Header closeButton>
          <Modal.Title className='settings-modal-title'>Settings</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {props.children}
        </Modal.Body>
      </Modal>
    </>
  );
}

export default SettingsModal;
