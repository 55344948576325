import React from 'react';
import PropTypes from 'prop-types';

import ToggleHeader from './ToggleHeader';

ModalPanel.propTypes = {
  panelTitle: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  panelExpansionSettings: PropTypes.object,
  updatePanelExpansionSettings: PropTypes.func,
  summary: PropTypes.string,
  additionalHeaderClasses: PropTypes.string,
  panelClass: PropTypes.string,
};

/**
 * A collapsible panel used in modal settings dialogs
 * @param { Object } props
 * @return { JSX.Element } the modal
 */
function ModalPanel(props) {
  const [localExpanded, setLocalExpanded] = React.useState(true);

  let isExpanded = localExpanded;
  let setIsExpanded = setLocalExpanded;

  if (props.panelExpansionSettings !== undefined) {
    const key = props.panelTitle;
    const passedValue = props.panelExpansionSettings[key];

    isExpanded = [passedValue, props.expandByDefault, false].find((option) => {
      return option !== undefined;
    });

    setIsExpanded = (newValue) => {
      const newSetting = {};
      newSetting[key] = newValue;
      props.updatePanelExpansionSettings(newSetting);
    };
  }

  return (
    <div className='settings-modal-panel'>
      <ToggleHeader
        title={props.panelTitle}
        toggleValue={isExpanded}
        toggleFunction={setIsExpanded}
        summary={props.summary}
        additionalClasses={props.additionalHeaderClasses}
      />

      {
        isExpanded ?
          <div
            className={`settings-modal-panel-content ${props.panelClass || ''}`}
          >
            { props.children }
          </div> :
          null
      }
    </div>
  );
}

export default ModalPanel;
