import React from 'react';
import PropTypes from 'prop-types';

import DisableStructuralChangesContext from './survey_editor/DisableStructuralChangesContext';
import {ErrorBoundary} from 'react-error-boundary';

import SurveyEditor from './survey_editor/SurveyEditor';

import ErrorFallback from './ErrorFallback';

SurveyEditorWrapper.propTypes = {
  // See SurveyEditor
  surveyId: PropTypes.number.isRequired,
  questionData: PropTypes.array.isRequired,
  surveyData: PropTypes.object.isRequired,
  disableStructuralChanges: PropTypes.bool.isRequired,

  // See PreviewPanel
  surveyPreviewData: PropTypes.object.isRequired,
  authenticityToken: PropTypes.string.isRequired,
  livePreviewUrl: PropTypes.string,

  // See SurveySubNav
  subnavLinks: PropTypes.array.isRequired,

  // See SurveySettingsSiderbar
  openTabName: PropTypes.string,
  surveyTargetingOptions: PropTypes.object.isRequired,
  surveyFormattingOptions: PropTypes.object.isRequired,
  surveyTags: PropTypes.array,
  auditLog: PropTypes.array.isRequired,
  htmlAttributeMap: PropTypes.object.isRequired,

  // See SurveyListSiderbar
  surveyListData: PropTypes.array.isRequired,
};

/**
 * A wrapper component for the survey edit page's content
 * @param { Object } props -- see PropTypes
 * @return { JSX.Element }
*/
function SurveyEditorWrapper(props) {
  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
    >
      <DisableStructuralChangesContext.Provider value={props.disableStructuralChanges}>
        <SurveyEditor
          surveyId={props.surveyId}
          questionData={props.questionData}
          surveyData={props.surveyData}
          surveyPreviewData={props.surveyPreviewData}
          authenticityToken={props.authenticityToken}
          livePreviewUrl={props.livePreviewUrl}
          subnavLinks={props.subnavLinks}
          openTabName={props.openTabName}
          surveyTargetingOptions={props.surveyTargetingOptions}
          surveyFormattingOptions={props.surveyFormattingOptions}
          surveyTags={props.surveyTags}
          auditLog={props.auditLog}
          surveyListData={props.surveyListData}
          htmlAttributeMap={props.htmlAttributeMap}
        />
      </DisableStructuralChangesContext.Provider>
    </ErrorBoundary>
  );
}

export default SurveyEditorWrapper;
