import React from 'react';
import PropTypes from 'prop-types';

import ExpandSidebarIcon from '../../images/survey_dashboard/expand.svg';
import CollapseSidebarIcon from '../../images/survey_dashboard/collapse.svg';

QrveySidebar.propTypes = {
  links: PropTypes.array.isRequired,
};

/**
 * A wrapper component for the survey edit page's left survey list sidebar
 * @param { Object } props
 * @return { SurveyListSidebar }
*/
function QrveySidebar(props) {
  const [sidebarPanelExpanded, setSidebarPanelExpanded] = React.useState(true);
  const expansionClass = sidebarPanelExpanded ? 'expanded' : 'collapsed';

  return (
    <div className={`survey-list-sidebar qrvey-sidebar ${expansionClass}`}>
      <div
        className={`sidebar-tab ${expansionClass} left-side`}
        onClick={() => {
          setSidebarPanelExpanded(!sidebarPanelExpanded);
        }}
      >
        <img
          className="folding-arrow-icon"
          src={sidebarPanelExpanded ? CollapseSidebarIcon : ExpandSidebarIcon}>
        </img>
      </div>

      <div className='sidebar-body'>
        <ul className='sidebar-list'>
          {
            props.links.map((link) => {
              let className = 'qrvey-list-item';

              if (link.current) {
                className = `${className} current`;
              }

              className = `${className} ${link.additionalClasses}`;

              return (
                <li className={className} key={link.text}>
                  <a href={link.url}>{link.text}</a>
                </li>
              );
            })
          }
        </ul>
      </div>
    </div>
  );
}

export default QrveySidebar;
