import React from 'react';
import PropTypes from 'prop-types';

SurveySubNav.propTypes = {
  selectedTab: PropTypes.string.isRequired,
  subnavLinks: PropTypes.array.isRequired,
};

/**
 * A survey subnavigation bar
 * @param { Object } props
 * @return { JSX.Element }
*/
function SurveySubNav(props) {
  const SubnavLink = ({url, label, name}) => {
    return (
      <li className={tabClass(name)}>
        <a href={url}>{label}</a>
      </li>
    );
  };

  const SubnavPopupLink = ({name, subNavLabel, popupLinks}) => {
    const [showPopup, setShowPopup] = React.useState(false);

    return (
      <li
        className={`report-subnav-link ${tabClass(name)}`}
        onMouseEnter={() => setShowPopup(true)}
        onMouseLeave={() => setShowPopup(false)}
      >
        <span >{subNavLabel}</span>
        {
          showPopup ?
            <div className='report-subnav-popup'>
              {
                popupLinks.map((link) => {
                  return (
                    <div key={link.label}>
                      <a href={link.url}>
                        {link.label}
                      </a>
                    </div>
                  );
                })
              }
            </div> : null
        }
      </li>
    );
  };

  const tabClass = (tabName) => {
    return props.selectedTab === tabName ? 'selected' : '';
  };

  return (
    <div className='survey-subnav-container'>
      <ul className='survey-subnav'>
        {
          props.subnavLinks.map((link) => {
            return (
              // Display links through a popup when there are multiple links
              link.popupLinks ? // E.g report url & page event url
                <SubnavPopupLink
                  name={link.name}
                  subNavLabel={link.subNavLabel}
                  popupLinks={link.popupLinks}
                /> :
                <SubnavLink
                  key={link.name}
                  url={link.url}
                  label={link.label}
                  name={link.name}
                />
            );
          })
        }
      </ul>
    </div>
  );
};

export default SurveySubNav;
