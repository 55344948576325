import React from 'react';
import PropTypes from 'prop-types';

import Modal from 'react-bootstrap/Modal';

SurveyDeletionModal.propTypes = {
  archiveSurveyUrl: PropTypes.string.isRequired,
  authenticityToken: PropTypes.string.isRequired,
  deleteSurveyUrl: PropTypes.string.isRequired,
  numAnswers: PropTypes.number.isRequired,
  surveyName: PropTypes.string.isRequired,
};

/**
 * A modal dialog to delete a survey
 * @param { Object } props
 * @return { JSX.Element } the survey deletion modal
*/
function SurveyDeletionModal(props) {
  const [
    confirmationTextMatches,
    setConfirmationTextMatches,
  ] = React.useState(false);

  const [show, setShow] = React.useState(true);

  const handleClose = () => {
    setShow(false);
  };

  const handleKeyUp = (e) => {
    const textFieldValue = e.target.value;
    const valuesMatch = textFieldValue === props.surveyName;

    setConfirmationTextMatches(valuesMatch);
  };

  return (
    <>
      <a href='#' onClick={handleClose} />

      <Modal
        style={{opacity: 1}}
        show={show}
        onHide={handleClose}
        centered
        className='survey-deletion-modal'
      >
        <Modal.Header closeButton>
          <Modal.Title className='settings-modal-title'>Destroy A Survey</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p className='deletion-warning'>
            Are you sure you want to destroy this survey?
            This action will also destroy all {props.numAnswers} answers.
            This action cannot be undone.
            <strong>Answers will not be recoverable</strong>.
          </p>

          <p>
            (Note you can also archive, which removes the survey from
            the default view, but preserves answers.)
          </p>

          <p>
            Please type the following to confirm: <strong>{props.surveyName}</strong>
          </p>
          <input onKeyUp={handleKeyUp} size={props.surveyName.length} />
        </Modal.Body>

        <Modal.Footer>
          <form
            action={props.archiveSurveyUrl}
            method='POST'
          >
            <input type='hidden' name='_method' value='patch' />
            <input
              type='hidden'
              name='authenticity_token'
              value={props.authenticityToken}
            />
            <button
              className='btn btn-secondary'
              href={props.archiveSurveyUrl}
            >
              Archive survey
            </button>
          </form>

          <form
            action={props.deleteSurveyUrl}
            method='POST'
          >
            <input type='hidden' name='_method' value='delete' />
            <input
              type='hidden'
              name='authenticity_token'
              value={props.authenticityToken}
            />
            <button
              className='btn btn-danger'
              href={props.deleteSurveyUrl}
              disabled={!confirmationTextMatches}
            >
              Yes, destroy survey and answers
            </button>
          </form>

          <button className='btn btn-secondary' onClick={handleClose}>
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default SurveyDeletionModal;
